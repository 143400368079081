.immersive_awselect {
  overflow: hidden !important
}

.awselect_bg {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, .8);
  -webkit-transition: .2s ease-in;
  -moz-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in
}

.awselect,
.awselect>.bg {
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1);
  float: left;
  width: 100%
}

.awselect_bg.animate {
  opacity: 1
}

.awselect {
  position: relative;
  cursor: pointer;
  transition: .24s cubic-bezier(0, 0, .58, 1)
}

.awselect>.bg {
  background: #fff;
  height: 0%;
  position: absolute;
  z-index: 1;
  transition: .24s cubic-bezier(0, 0, .58, 1)
}

.awselect .front_face {
  position: relative;
  padding: 0 1rem !important;
  line-height: 2rem;
  float: left;
  width: 100%;
  background: transparent;
  box-shadow: 0 0 0 1px #ccc;
  border-radius: 1rem;
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1);
  transition: .24s cubic-bezier(0, 0, .58, 1);
}

.awselect:hover .front_face,
.awselect.animate .front_face {
  border-radius: 0;
}

.awselect .front_face>.bg {
  position: absolute;
  z-index: 0;
  float: left;
  width: 100%;
  height: 100%;
  left: 0;
  background: #fff;
  top: 0
}

.awselect .front_face .content {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-itemPixes: center;
}

.awselect .front_face span {
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1);
  transition: .24s cubic-bezier(0, 0, .58, 1);
  display: inline-block;
  color: inherit
}

.awselect .front_face .current_value {
  position: absolute;
  left: 0
}

.awselect .front_face .placeholder {
  position: relative
}

.awselect .back_face,
.awselect .front_face .icon {
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1)
}

.awselect .front_face .icon {
  margin-left: 1rem;
  line-height: 1;
  transform-origin: center 0;
  transition: .24s cubic-bezier(0, 0, .58, 1)
}

.awselect .front_face .icon svg {
  width: 15px;
  height: 15px;
  opacity: 0.4;
  margin-bottom: 1px;
}

.awselect .back_face {
  position: absolute;
  z-index: 2;
  padding: 0;
  transition: .24s cubic-bezier(0, 0, .58, 1);
  transform: translateY(20px);
  opacity: 0;
  float: left;
  width: auto;
  min-width: 100%;
  background-color: white;
  max-height: 200px;
  overflow: hidden;
  display: none
}

.aw_cursor_pointer,
.awselect .back_face ul li a {
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1);
  float: left
}

.awselect .back_face.overflow {
  overflow-y: scroll
}

.awselect .back_face ul {
  margin: 0;
  float: left;
  padding: 0;
  width: 100%
}

.awselect .back_face ul li {
  float: left;
  width: 100%;
  padding: 0;
  line-height: 2rem;
  white-space: nowrap;
}

.awselect .back_face ul li a {
  color: inherit;
  opacity: .8;
  width: 100%;
  padding: 0 1rem !important;
  transition: .24s cubic-bezier(0, 0, .58, 1)
}

.awselect .back_face ul li a:hover {
  background: rgba(0, 0, 0, .07);
  opacity: 1
}

.awselect.animate {
  z-index: 9999
}

.awselect.animate .front_face .icon {
  /* transform: rotate(0) translateY(-50%) */
}

.awselect.animate>.bg {
  height: 100%
}

.awselect.animate2 .back_face {
  transform: translateY(0);
  opacity: 1;
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.02), 0 11px 15px -7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.02), 0 11px 15px -7px rgba(0, 0, 0, 0.1);
}

.awselect.hasValue .front_face .placeholder {
  transform: translateY(20px);
  opacity: 0
}

.awselect.placeholder_animate .front_face .current_value {
  opacity: 0
}

.awselect.placeholder_animate2 .front_face .placeholder {
  transform: translateY(0);
  opacity: 1
}

.awselect.transition_paused {
  transition: none !important
}

.aw_cursor_pointer {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #ddd;
  overflow: hidden;
  padding: 5px;
  transition: .24s cubic-bezier(0, 0, .58, 1);
  transform: scale(.5)
}

.aw_cursor_pointer .inner {
  float: left;
  width: 100%;
  height: 0%;
  background: #fff;
  border-radius: 100%;
  -webkit-transition: .24s cubic-bezier(0, 0, .58, 1);
  -moz-transition: .24s cubic-bezier(0, 0, .58, 1);
  -o-transition: .24s cubic-bezier(0, 0, .58, 1);
  transition: .24s cubic-bezier(0, 0, .58, 1)
}

.aw_cursor_pointer.animate {
  transform: scale(1)
}

.aw_cursor_pointer.animate .inner {
  height: 100%
}